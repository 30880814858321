/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@font-face {
  font-family: 'TT-Norms-Pro';
  src: local('TT-Norms-Pro-Regular'), url(./fonts/TT-Norms-Pro-Regular.ttf) format('truetype');
}

:root {
  --primary: #3d5ba6;
  --secondary: #BAC4CC;
}

html {
  scrollbar-color: #3d5ba6 #e0e0e0;
  scrollbar-width: thin;
}
.App {
  text-align: center;
  font-family: 'TT-Norms-Pro', sans-serif;
  position: relative;

}
.App-header {
  display: inline;
  width: 100%;
  height: 100px;
  margin: 0px 0px 0px 0px;
  scroll-behavior: smooth;
}
.App-footer {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3em;
  text-align: left;
  color:#ffffff;
  scroll-snap-align: start;
  /* height: 100vh; */
  width: 100%;
  /* padding-top: 9vmin; */
}
.App-footer-bar {
    /* position: absolute; */
    /* bottom: 0%; */
    width: 100%;
    /* height: 80vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.scroll-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    /* scroll-behavior must be on the container */
}
.section {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    height: 100vh;
    display: flex;
    justify-content: center;
    position:relative;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
}

.abs-center {
    position:absolute;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%);
}
.section-text {
  background-color: transparent;
  margin: 30px auto;
  /* width: 60%;
  position: relative; */
  letter-spacing: 1px;
  font-size: 2em;
  line-height: 2.1em;
  text-align: center;
}
.bg {
    position: relative;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.bg-services { background-image: url('./images/bg1.jpg'); overflow: hidden; }
.bg-s1 { background-image: url('./images/bg-fleet.jpeg'); overflow: hidden; }
.bg-a2 { background-image: url('./images/bg-services.jpeg'); overflow: hidden; }
.bg-a3 { background-image: url('./images/bg-a.JPG'); overflow: hidden; }
.bg-n { 
/*    background-image: url('./images/aegeanSpire.jpg');*/
    background-image: linear-gradient(rgba(255,255,255,0.6), 20%, transparent), url('./images/aegeanSpire.jpg'); 
    overflow: hidden; 
}
.bg-a1 {
    background-image: linear-gradient(rgba(255,255,255,0.6), 15%, transparent), url('./images/bg-header.jpeg');
    overflow: hidden;
}
.bg-sl1 { background-color: #333 }
.bg-sl2 { background-color: #3d5ba6 }
.bg-desktop {
    background-image: linear-gradient(20deg, rgba(0,0,0,0.6) 35%, transparent 60%), url('./images/bg-footer.jpeg');
    overflow: hidden;
}
.bg-cover {
    position: absolute;
    overflow: hidden;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: #666;
    opacity: 0.35;
    /* transform: translateX(-20%); */
    /* transform: translate(0px) rotateZ(-20deg); */
    transform-origin: 50% 50%;
    transition-property: all;
    transition-duration: 1.5s;
    transition-timing-function: ease-out;
    transition-delay: 0s;
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}
.animate {
    /* top: 25%; */
    top:0%;
    height:120%;
    width:120%;
    opacity:0.3;
}
/* .bg-cover-line {
    position:absolute;
    overflow: hidden;
    top:30%;
    width:150%;
    height:500px;
    background-color: red;
    opacity: 1;
    transform: translate(0px) rotateZ(-25deg);
    transform-origin: 50% 50%;
    transition-property: all;
    transition-duration: 2s;
    transition-timing-function: linear;
    transition-delay: 0s;
} */
.color-white {
    color:#ffffff;
    text-shadow:1px 2px 2px #000
}
.tab-text {
    background-color: transparent;
    letter-spacing: 1px;
    font-size: 1.6em;
    line-height: 1.4em;
    padding: 10px 10px;
    text-align: center;
    overflow: hidden;
}
.w-80 { width: 80% }
.w-50 { width: 50% }
.w-40 { width: 40% }
.site-page {
  position: relative;
}
.about { position: relative; width: 100%; }
.services,
.fleet,
.news {
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  width: 100%;
}
.services { background-image: url('./images/shipyard.jpg'); }
.news { background-image: url('./images/aegeanSpire.jpg'); }

.bm-burger-button {
  position: fixed;
  width: 40px;
  height: 30px;
  left: 36px;
  top: 36px;
  color: red;
}
.bm-burger-bars {
  background: #373a47;
}
.bm-cross-button {
  height: 24px;
  width: 24px;
}
.bm-cross {
  background: #bdc3c7;
}
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.menu-item {
  color:  #3d5ba6;
  padding-bottom: 30px;
  text-decoration: none;
  font-size: 30px;
}
.menu-item--small {
  color:  red;
  padding-bottom: 20px;
  text-decoration: none;
}
.mainlogo {
  /* background-image: url('./images/SeaSpire_logo2.png'); */
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* position: relative;
  top: 1%; */
  display: block;
  margin-top: 20px;
  animation-name: slideInUp;
  animation-duration: 5s;
  animation-iteration-count: 1;
}
.tester {
    height:800px;
}
.menu {
  width: 100%;
  /* height: 150px; */
  margin: 0;
  display: flex;
  justify-content: flex-start;
}
.main {
    margin-top: 40px;
    padding: 5px 0px;
}
.mobile-link {
  outline: none;
  font-size: 1.1em;
  text-decoration: none;
  color: #3d5ba6;
}
.menu ul {
  color: #3d5ba6;
  text-transform: uppercase;
  display: block;
  /* position: absolute; */
  padding: 0;
  margin: 0;
  padding: 0px 0px;
  letter-spacing: 2px;
  width: 100%;
}
.menu li {
  color: #3d5ba6;
  padding: 2vh 4vw;
  font-weight: 700;
  display: inline-block;
  font-size: 1.2em;
  position: relative;
  transition: all 750ms ease-out;
}
.menu a {
  display: inline-block;
  color: var(--primary);
  text-decoration: none;
}
.menu li::after {
  content: "";
  display: block;
  margin: auto;
  position:relative;
  height: 3px;
  width: 0;
  top: 5px;
  left: 0;
  background: transparent;
  transition: all 0.3s;
}
.menu li:hover::after {
  width: 100%;
  background: var(--primary);
}
/*old CSS onHover transition*/
/*.menu li::before,
.menu li::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  opacity: 0;
  background-color: #3d5ba6;
  border: 0.5px solid #3d5ba6;
  transition: opacity 2s;
  transition: transform 2s;
  left: 0;
}
.menu li::before {
  top: -5%;
  transform: translateY(50px;);
}
.menu li::after {
  bottom: -5%;
  transform: translateY(-50px;);
}
.menu li:hover::before {
  opacity: 1;
  transform: translateY(50px);
}
.menu li:hover::after {
  opacity: 1;
  transform: translateY(-50px);
}*/

.mainpic {
  /* background-image: url('./images/saronic.jpg'); */
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-attachment: local;
  height: 500px;
  width: 100%;
}
.dropdown-container {
  display: inline-block;
  position: relative;
}
.dropdown-title {
  background-color: #3d5ba6;
  color: #AFBDC5;
  font-size: 30px;
  outline: 0;
  border: none;
  cursor: pointer;
}
.dropdown-content {
  display: none;
  position: absolute;
  width: 400px;
  z-index: 1;

}
.dropdown-content li {
  display: block;
  position: relative;
  font-size: 30px;
  color: #3d5ba6;
  text-align: center;
  padding: 10px 10px;
  margin: 10px;
  background-color: white;
  cursor: pointer;
}
.dropdown-content ul {
  /*position: relative;*/
  list-style-type: none;
  padding: 0;
}
.dropdown-content li:hover {
  background-color: #AFBDC5;
}
.dropdown-container:hover
.dropdown-content {
    display: block;
}
.sticky {
  margin: 0;
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  transform-origin: center bottom;
}
.scrollArrowTop {
  display: inline-block;
  outline: none;
  color: #3d5ba6;
  letter-spacing: 2px;
  padding: 50px 10px 0px 10px;

}
.scrollArrowBot {
  display: inline-block;
  outline: none;
  color: #ffffff;
  letter-spacing: 2px;
  padding: 20px 10px 0px 10px;
  transform: translateY(-350px);
  z-index: -1;
}
.scrollArrowTop:before,
.scrollArrowBot:before {
  opacity: 1;
  width: 100%;
  -webkit-transform: translate(-150%,0);
  -moz-transform: translate(-150%,0);
  -ms-transform: translate(-150%,0);
  -o-transform: translate(-150%,0);
  transform: translate(-150%,0);
}
.scrollArrowTop:hover:before {
  opacity: 1;
  color: #3d5ba6;
  content: attr(data-hover);
  font-size: 12px;
  cursor: pointer;
  font-family: arial;
}
.scrollArrowBot:hover:before {
  opacity: 1;
  color: #ffffff;
  content: attr(data-hover);
  font-size: 12px;
  cursor: pointer;
  font-family: arial;
}
.scroll-top {
    background-color: #ffffff !important;
}
.slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
}
.sliderContainer {
  position: relative;
  width: 100%;
  background-color: transparent;
}
.slide {
  display: inline-block;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.slide-table { height: 30vh; }
.sliderTable td { color: #ffffff; background-color: #3d5ba6; }
.slide-img {
    height: 70vh;
    padding-top: 85px;
    margin: 0px 15%;
    position: relative;
}
.slide-table p { color: #ffffff; font-size: 2em; }
.slide-img p {
    color: #ffffff;
    font-size: 3em;
    position: absolute; bottom: 5% ; left: 50%;
    transform: translateX(-50%);
    text-shadow: 2px 2px 2px #000;
}
.vessel1 {
  background-size: contain;
  height:100%;
}
.slideText,
.slide1Text,
.slide3Text {
  background-color: #AFBDC5;
  color: #3d5ba6;
}
.slideText strong,
.slide1Text strong,
.slide3Text strong {
  font-size: 30px;
}
.slideText p,
.slide1Text p,
.slide3Text p {
  font-size: 35px;
  padding: 5px 5px;
  margin:0;
}

tr:nth-of-type(odd) {
  background: #eee;
}
th {
  background: #333;
  color: white;
  font-weight: bold;
}
td, th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}
.td1 {
  background-color: #3d5ba6;
  color: #AFBDC5;
  font-size: 20px;
}
.td2 {
  padding-left:15px;
  text-align:left;
  font-size: 16px;
}
.nextButton {
  position: absolute;
  top: 30%;
  right: 5%;
  cursor: pointer;
}
.rightButton:active {
  color: #AFBDC5;
  transform:translateX(5px);
}
.backButton {
  position: absolute;
  top: 30%;
  left: 5%;
  cursor: pointer;
}
.leftButton:active {
  color: #AFBDC5;
}
.footer-logo {
  width: 220px;
  margin: 10px auto;
}
.footer-row {
    position: relative;
    z-index: 1200;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 1vh 5vw;
    /* width:100%; */
}
.footer-link {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2em;
}
.news-link {
    color: var(--primary);
    font-size: 1em;
}
.footer-icon {
    color: #3d5ba6;
}
.footer-image {
    width:100%;
}
.icon-cont {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon-cont-fw {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-cont {
    display: flex;
    justify-content: flex-start;
    margin: auto;
    text-align: left;
}
/* .footer-details {
    margin: auto;
} */
.disclaimer {
  width: 100%;
  background-color: #AFBDC5;
  color: #3d5ba6;
  font-size: 0.9em;
  margin: 0;
  padding: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  bottom:0%;
}
h3 {
  font-size: 26px;
  padding: 15px 10px;
  text-align: center;
}
h4 {
  font-size: 20px;
  position: absolute;
  left: 10%;
  bottom: 15%;
}
h5 {
  font-size: 20px;
  color: #AFBDC5;
  padding: 10px 0px;
  background-color: #3d5ba6;
}

a.linkedin {
  color: #3d5ba6;
  background-color: #ffffff;
  cursor: pointer;
  height: 75px;
  width: 70px;
  text-decoration: none;
  padding: 0px 0px;
}
a.address {
  text-decoration: none;
  color: #3d5ba6;
}
a.phone {
  text-decoration: none;
  color: #3d5ba6;
  background-color: #ffffff;
}
.about_text {
  background-color: #ffffff;
  /*height: 1700px;*/
  width: 100%;
  position: relative;
  letter-spacing: 2px;
}
.text {
  padding: 15px 5px;
}
.text p {
  font-size: 20px;
  color: #3d5ba6;
  margin:  10px auto;
  padding: 10px 15px;
  width: 70%;
  text-align: left;
}
.text strong {
  font-weight: bold;
  font-size: 25px;
  color: #000000;
  margin:  10px 10px;
}
.text span {
  font-weight: 700;
  color: #000000;
}
.title-container {
    position: relative;
    height: 5vmin;
}
.title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5em;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 0.2em;
  text-shadow: 2px 2px 1px #666666;
}
.navBtnContainer {
    height: 2em;
    width: 2em;
}
.slide-container { width: 100%; height:100%;  }
.carousel-container { height: 100% }
.scrollTop {
    position: absolute;
    bottom: 1%; right: 1%;
    z-index:1000}
.nav-arrow { width: 2em !important; height: 2em !important }
.news-container {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  width: 60%;
}
.news-title {
  font-size: 2em;
  font-weight: 700;
  color: white;
  letter-spacing: 0.2em;
  margin: 2vh 2vw;
  text-shadow: 2px 2px #666666;
  position: absolute;
  top: 12vh;
}
.about_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 54px;
  font-weight: 500;
  color: white;
  letter-spacing: 0.2em;
}
.about_tag {
  position: absolute;
  top: 50%;
  left: 10%;
  padding: 2vh 2vw;
  color: #fff;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 0.1rem;
  z-index: 1200;
  /* background-color: rgba(255,255,255,0.2); */
  /* box-shadow: 1px 1px 10px #fff; */
  /* box-shadow: 0 35px 65px 0 rgba(186, 196, 204, 0.4), inset 0 -10px 15px 0 rgba(24, 100, 172, 0.8); */
  /* border-radius: 30px; */
  /* text-shadow: 1px 0.5px #ffffff; */
  /* animation-duration: 4s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideIn; */
}
.sea {
    color: #3d5ba6;
    font-size: 1.5em;
    font-weight: 700;
    text-shadow: 1px 1px #000000;
}
.spire {
    color: #e08121;
    font-size: 1.5em;
    font-weight: 700;
    text-shadow: 1px 1px #000000;
}
.header-root {
    flex-grow: 1;
    width: 100%;
}
.header-logo {
    height: 100%;
    width: 4vw;
}

.fleet_text {
  background-color: #ffffff;
  color: #000000;
  height: 300px;
  width: 100%;
  position: relative;
  letter-spacing: 1px;
}
.fleet_text strong {
  font-weight: bold;
  font-size: 25px;
  color: #AFBDC5;
  position: relative;
  text-align: center;
  top: 10%;
  color: black;
}
.fleet_text p {
  font-size: 20px;
  color: #3d5ba6;
  width: 60%;
  text-align: justify;
}
.fleet_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  font-weight: 500;
  color: #3d5ba6;
  letter-spacing: 0.2em;
}
.section-text h3 {
    color: #3d5ba6;
    font-size: 1.6em;
    line-height: 1.9em;
    font-weight: 700;
}
.section-text h5 {
  color: #3d5ba6;
  font-size: 1.4em;
  line-height: 1.6em;
  font-weight: 700;
  position: relative;
  text-align: left;
  background-color: transparent;
  padding: 10px 0px;
  margin:5px 0px;
}
.accordion-container {
    width: 75%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    position:absolute;
    top:15vh;
    overflow:hidden;
    z-index:1000;
}
.carousel-div {
  width:100%;
  height: 740px;
}
.prevArrow {
  position: absolute;
  top:50%;
  left:5%;
}
.prevArrow:hover,
.nextArrow:hover {
  color: white;
  cursor: pointer;
}
.ser1text h1 {
  color: #3d5ba6;
  font-size: 30px;
  margin: 0;
  padding-top: 20px;

}
.service1 {
  position: absolute;
  top:50%;
  /* left:50%; */
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: white;
}
.service2 {
  position: absolute;
  top:50%;
  /* left:50%; */
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: white;
}
.slick-dots {
  background-color: transparent;
  transform: translateY(-90%);
}
.slick-dots:hover {
  color: #ffffff;
}
#back-to-top-anchor { height: 1px; min-height:1px; }
.sm-official {
    width: 25vw;
    position: absolute;
    top: 30%;
    left: 60%;
    z-index: 1200;
}
.seaspire-logo {
    position:absolute;
    z-index: 2000;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    height: 50%;
    visibility: hidden;
    animation-duration: 4s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: fadeOutNleave;
}
.logo {
    position:relative;
}
.logo-bg {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position:absolute;
    z-index: 1500;
    width: 100%;
    height: 100%;
    opacity: 0;
/*    bottom: 0%;*/
    visibility: hidden;
    animation-duration: 4s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-name: fadeOut;
}
.logo-container {
    background-color: transparent;
    position:relative;
    width:100%;
    height:100vh;

}
.css-1gdwtqt-MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 !important;
}
.css-5xtk5g-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px !important;
}

@keyframes fadeOutNleave {
  0%   { opacity: 1; visibility: visible; top: 50%; }
  40%  { opacity: 1; top: 50%; }
  80%  { opacity: 0.8; }
  90%  { opacity: 0.4; }
  100% { opacity: 0; top: -25%; }
}
@keyframes fadeOutNleaveMob {
  0%   { transform: translateY(100%)  }
  100% { transform: translateY(-120%)  }
}
@keyframes fadeOut {
  0%   { opacity: 1; visibility: visible;  }
  40%  { opacity: 1; }
  80%  { opacity: 0.8; }
  90%  { opacity: 0.4; }
  100% { opacity: 0; }
}
@keyframes fadeIn {
  0%   { opacity : 1;   }
  50%  { opacity: 0.8;  }
  75%  { opacity: 0.5;  }
  90%  { opacity: 0.4;  }
  100% { opacity: 0;   }
}
@keyframes slideIn {
  0%   { transform: translateY(200%)  }
  100% { transform: translateY(0%)  }
}


@media only screen and (max-width: 600px) {
  body { min-height: 100vh; }
  .App,
  .App-Header,
  .menu,
  .dropdown-content,
  .site-page { width: 100%; }
  .App-footer { font-size:1em; justify-content: flex-start; }
  .bg {
      background-attachment: scroll;
  }
  .bg-a1 {
    background-image: linear-gradient(rgba(0,0,0,0.6), 15%, transparent), url('./images/bg-header.jpeg');
  }
  /*.section {
    height: 100vh;
    min-height: -webkit-fill-available;
  }*/
  .section-text {
    margin: 10px auto;
    font-size: 1.4em;
    line-height: 1.4em;
  }
  .section-text h5 { text-align: center; }
  .emailtext,
  .addresstext { font-size:16px; }
  .mainlogo {
    width: 100%;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    left:50%;
    display: block;
  }
  .menu {
    display:none;
  }
  .moboff { display: none}
  .disclaimer {
    height: 10vh;
  }
  .mainpic {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    height: 350px;
    width: 100%;
  }
  .map {
    position: relative !important;
    left: 0 !important;
    top: 1% !important;
  }
  .scrollArrowBot {
    color: #ffffff;
    transform: translateY(-900px);
  }
  .slide-img {
      width:100%;
      height:240px;
      padding-top: 50px;
      margin: 0;
  }
  .tab-text { font-size: 1.2rem }
  .tabs-services {
      align-self: flex-start;
      margin-top: 15vw
  }
  .slide-table { height: 50% }
  .nextButton,
  .backButton {
    top: 12%;
  }
  .td2 {
    padding-right:100px;
  }
  .service1 {
    top:25%;
  }
  .service2 {
    top:70%;
  }
  .slideText {
    height: 1100px !important;
  }
  .slide1Text {
    height: 1100px !important;
    background-color: #333 !important;
  }
  .slide3Text {
    height: 1100px !important;
    background-color: #3d5ba6 !important;
  }
  .text strong { text-align: center; padding: 15px 5px; }
  .about_tag {
      top:55%;
      left:50%;
      transform:translateX(-50%);
      padding: 10px;
      color: #000;
      font-size:0.9em;
      background-color: transparent;
      text-shadow: 1px 1px 1px #fff;
      box-shadow: 0px 0px 0px #fff;
  }
  .other_text { height: 800px; }
  .carousel-div {
    height: 1000px !important;
  }
  .ser1pic {
    background-size: contain !important;
    background-repeat: no-repeat;
    height: 300px !important;
  }
  .ser1text {
    height: 700px !important;
  }
  .prevArrow { top:10% !important; }
  .nextArrow { top:10% !important; }
  .text p { width: 90%; }
  .news-container {
    width: 94% !important; margin: 10px auto;
  }
  .news-title {
    font-size: 1.8em;
    top: 7vh;
  }
  /* .about { background-attachment: scroll !important; } */
  .services,
  .fleet,
  .news,
  .slide-img {
    background-attachment: scroll !important;
    /* -webkit-background-size: contain !important;
    -moz-background-size: contain !important;
    -o-background-size: contain !important;
    background-size: contain !important; */
  }
  .slide-img {
      -webkit-background-size: contain !important;
      -moz-background-size: contain !important;
      -o-background-size: contain !important;
      background-size: contain !important;
  }
  .services,
  .other { height: 310px; }
  .table_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
  }
  .table-spec { padding: 5px 10px  !important}
  .navBtnContainer { width: 1em; height: 1em}
  .accordion-container { width: 95%; top:10vh; }
  .ship-tag { font-size: 1em !important }
  .header-logo {
      width: 10vw;
      padding: 3px;
  }
  .header-root {
      flex-grow: 0;
  }
  .nav-arrow { width: 1em !important; height: 1em !important;}
  .footer-logo {
      width: 140px;
      overflow: hidden;
      margin: 10px 5px
  }
  .footer-row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-basis:60%;
      overflow:visible;
      width: 90vw;
  }
  .footer-details {
      width: 80vw;
      display:flex;
      flex-direction:column;
      justify-content: center;
      align-items: flex-start;
  }
  .footer-cont { margin: 10px 2px; font-size:0.8em;}
  .pt-2 { padding-top:10vh;}
  .sm-official {
      width: 60vw;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
  }
  .footer-link { font-size: 1em; }
  .ase { align-self: flex-end; margin-right: 10vw }
}
@media only screen and (min-width: 600px){
    .menu li { font-size: 1em; }
    .bg { background-attachment: scroll; }
    .about_tag {
      top: 35%;
      left: 3%;
      padding: 2vh 2vw;
    }
    .sm-official {
        width: 30vw;
        top: 12%;
        left: 65%;
    }
    .bg-desktop { background-position: 65% 25%;}
    .footer-logo {
      width: 220px;
      transform:translateY(-13vh);
    }
    .header-logo {
        width: 60px;
        padding: 1px;
    }
}
@media only screen and (min-width:900px) {
    .bg { background-attachment: fixed; }
    .footer-logo { width:20vh; transform:translateY(-8vh); }
    .header-logo {
        width: 60px;
        padding: 2px;
    }
    .news-title {
      top: 15vh;
    }
    .sm-official {
        width: 30vw;
        top: 20%;
        left: 65%;
    }
}
@media only screen and (min-width: 1200px){
  .main {
    margin-top: 150px;
    padding: 0px;
  }
  .mainpic {
    background-size: cover;
    height: 380px;
    width: 100%;
  }
  .mainlogo {
    width: 300px;
    transform: translateX(-50%);
    left:50%;
    display: block;
  }
  .vessel1 {
    background-size:cover;
    height:60vh;
    width:100%;
  }
  .menu li { font-size: 1.2em; padding: 1vh 2vw; }
  .header { height:100px; }
  .about_text p { text-align: justify; }
  .other_text { height: 250px; }
  .bm-burger-button { display:none; }
  .accordion-container { width: 65%; }
  .about_tag { top: 55%; left: 10%; }
  .sm-official {
      width: 20vw;
      top: 20%;
      left: 65%;
  }
  .footer-logo { transform:translateY(-8vh)}
  .footer-row { width: 50vw; justify-content: center; }
}
@media only screen and (min-width: 1536px) {
    .accordion-container { width: 50%; }
}

@keyframes xfade{
  17% {
    opacity:0.3;
  }
  25% {
    opacity:0.5;
  }
  92% {
    opacity:0.8;
  }
  100% {
    opacity:1;
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translate(-50%,50%);
    transform: translate(-50%,50%);
    visibility: visible;
    opacity:0;
  }
  100% {
    -webkit-transform: translateY(-50%,0);
    transform: translateY(-50%,0);
    opacity:1;
  }
}
@keyframes bounce {
    0%, 20%, 53%, 80%, 100% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }
    40%, 43% {
      -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }
    70% {
      -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }
    90% {
      -webkit-transform: translate3d(0,-2px,0);
      transform: translate3d(0,-2px,0);
    }
  }
